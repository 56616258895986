import { ARBITRUM, AVALANCHE, BASE, BERACHAIN_BARTIO, FANTOM, OP } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-arb-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-arb-referrals/version/latest",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmy-vault",
  },
  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmy-io/gmy-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmy-io/gmy-avalanche-referrals",
  },
  [OP]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-op-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-op-referrals/version/latest",
  },
  [BASE]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-base-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-base-referrals/version/latest",
  },
  [FANTOM]: {
    stats: "https://api.studio.thegraph.com/query/74668/gummy-fantom-stats/version/latest",
    referrals: "https://api.studio.thegraph.com/query/74668/gummy-fantom-referrals/version/latest",
  },
  [BERACHAIN_BARTIO]: {
    stats: "https://api.yummy.fi/subgraph/bartio/query/stats",
    referrals: "https://api.yummy.fi/subgraph/bartio/query/referrals",
    positions: "https://api.yummy.fi/subgraph/bartio/query/middleware",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
